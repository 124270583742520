import React, { useState } from "react";
import { Container} from "@mui/material";
import DrawerPrincipal from "../../component/drawer/drawer-principal";
import MainKamban from "../../component/kamban/main";
import CustomizedBreadcrumbs from "../../component/breadcrumbs/breadcrumbs";


function ListBudget(){
  
    return(

        <>

        <Container maxWidth="lx" >
            <DrawerPrincipal
                title={"Orçamento"}
                divOpen={
                <div>
                    <div>
                    <div className="row mb-3">
                        <CustomizedBreadcrumbs title1={"Home"} link1={"/"} title2={"Lista de Orçamento"} link2={"#"} />
                    </div>
                    
                    <div style={{ width: '100%', padding: 1 }}>
                        <MainKamban />
                    </div>
               
                </div>
            </div>
            }
        />
            </Container>

        </>

    )



}


export default ListBudget;